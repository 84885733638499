import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";
import { axiosInstance } from "../api/AxiosInstance";
import { sendEmailVerification } from "firebase/auth";
import { env } from "../env";
import { getMyIpInfo } from "../api/iplocation";
import moment from "moment-timezone";

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  authDomain: env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: env.REACT_APP_FIREBASE_APPID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const userLanguage = navigator.language || navigator.userLanguage;
    const userLanguageSplitted = userLanguage.split("-");
    const language = userLanguageSplitted[0];

    let country = null;
    try {
      const ipInfo = await getMyIpInfo();
      country = ipInfo.countryCode;
    } catch (e) {
      country = userLanguageSplitted[1];
    }

    const payload = {
      id: user.uid,
      firebase_id: user.uid,
      display_name: user.displayName,
      profile_pic_url: user.photoURL,
      auth_provider: "google",
      email: user.email,
      country: country,
      language: language,
      timezone: moment.tz.guess()
    };
    await axiosInstance.post("/users/signup", payload);
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const registerWithEmailAndPassword = async (display_name, email, password, country, language, timezone) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await sendEmailVerification(res.user);

    const payload = {
      id: user.uid,
      display_name: display_name,
      auth_provider: "local",
      email: email,
      country: country,
      language: language,
      timezone: timezone
    };

    await axiosInstance.post("/users/signup", payload);

  } catch (err) {
    console.error(err);
    throw err;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logout = () => {
  signOut(auth);
  window.location.replace("/login");
};

export {
  app,
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout
};
