import axios from "axios";
import { auth } from "../auth/firebase";
import { env } from "../env";

const axiosInstance = axios.create({
  baseURL: env.REACT_APP_ROBOPOST_API_BASE_URL,
  timeout: 600000
});

// Send token with axios
axiosInstance.interceptors.request.use(async config => {
  if (auth.currentUser) {
    const idToken = await auth.currentUser.getIdToken();
    if (idToken) {
      config.headers.authorization = `Bearer ${idToken}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export { axiosInstance };
