import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CLink,
  CLoadingButton,
  CRow
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilArrowLeft } from "@coreui/icons";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { auth, sendPasswordReset } from "../../../auth/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AppToaster } from "../../../components/toaster";
import logo from "../../../assets/images/logo.png";

const ForgotPassword = () => {

  const [user, loading, error] = useAuthState(auth);
  const [isSent, setIsSent] = useState(false);
  const {t} = useTranslation();

  const handleSubmit = async (values) => {
    try {
      await sendPasswordReset(values.email);
      setIsSent(true);
    } catch (e) {
      if (e.code === "auth/user-not-found") {
        AppToaster.show({
          message: t('message:email:emailNotFound'),
          intent: "danger",
          timeout: 2500
        });
      } else {
        AppToaster.show({
          message: e.code,
          intent: "danger",
          timeout: 2500
        });
      }
      console.log(e);
    }
  };

  return (
    <div className="forgot-password-wrapper min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol className="clearfix mb-4" md={12}>
            <CLink href="https://www.robopost.app"><CImage src={logo} align="center" height={50}></CImage></CLink>
          </CCol>
          <CCol md={10} lg={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {!isSent ? <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      email: ""
                    }}
                    validationSchema={
                      Yup.object().shape({
                        email: Yup.string()
                          .email(t('message:email:invalidEmail'))
                          .required(t('message:requiredValue'))
                      })
                    }
                    onSubmit={handleSubmit}>
                    {({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit
                      }) =>
                      <div>
                        <h4>{t('forgotPassword:title')}</h4>
                        <p className="text-medium-emphasis mb-4">{t('forgotPassword:description')}</p>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>@</CInputGroupText>
                          <CFormInput placeholder={t('placeholder:email')}
                                      onChange={(event) => {
                                        setFieldValue("email", event.target.value);
                                      }}
                                      invalid={errors.email ? true : false}
                                      feedbackInvalid={errors.email}
                          />
                        </CInputGroup>
                        <CRow className="mb-5">
                        <CCol xs={12} className="text-center d-grid gap-2">
                          <CLoadingButton onClick={() => {
                            handleSubmit(values);
                          }} color="success">{t('forgotPassword:send')}</CLoadingButton>
                        </CCol>
                        </CRow>
                      </div>
                    }
                  </Formik> : <div>
                    <h3>{t('forgotPassword:sentEmail')}</h3>
                  </div>}
                  <div className="mt-4 text-start">
                    <Link to={"/login"}>
                      <CIcon icon={cilArrowLeft} />&nbsp;{t('forgotPassword:backToLogin')}
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
