import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../auth/firebase";
import { acceptInvitation, getInvitation } from "../../api/APIClient";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { AppToaster } from "../../components/toaster";
import { useTranslation } from "react-i18next";

const Invitation = () => {

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const parsed = queryString.parse(location.search);
      const invitationToken = parsed.invitation_token;
      try {
        const invitation = await getInvitation(invitationToken);
        if (user) {
          try {
            await acceptInvitation(invitationToken);
            AppToaster.show({
              message: t("team:invitationAccepted"),
              intent: "success",
              timeout: 2500
            });
          }
          catch (e) {
            AppToaster.show({
              message: t("team:invitationInvalid"),
              intent: "danger",
              timeout: 2500
            });
          }
          navigate(`/team`);
        } else {
          if (invitation.user_from_email) {
            navigate(`/login?invitation_token=${invitationToken}`);
          }
          else {
            navigate(`/register?invitation_token=${invitationToken}`);
          }
        }
      } catch (e) {
        AppToaster.show({
          message: t("team:invitationInvalid"),
          intent: "danger",
          timeout: 2500
        });
        navigate("/");
      }
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate, location, t]);

  return (
    <></>
  );
};

export default Invitation;
