import axios from "axios";

const getMyIpInfo = async () => {
  const { data } = await axios.get("https://pro.ip-api.com/json?key=cZA6Fwa6EWh0J6F");
  return data;
};

export {
  getMyIpInfo
};
